body {
  height: 100%;
  width: 100%;
}

.App-header {
  background-color: #464c58;
  display: flex;
  margin-left: 20px;
  padding: 10px;
  margin-top: 20px;
  font-size: calc(10px + 2vmin);
  color: white;
}

.MuiSvgIcon-root {
    color: #71bf4f;
}

hr {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-style: groove;
  border-width: 2px;
}

a {
  line-height: 20px;
  padding: 2px;
  padding-right: 5px;
}
a:hover {
  color: #D22630;
}
.MuiAutocomplete-root {
    width: inherit;
}

.section {
  margin: 80px 127px;
  .options {
    float: right;

    .icon-image {
      width: 36px;
      height: 36px;
      margin-left: 20px;
      cursor: pointer;
    }
  }
}

/* App header */
.header {
  background-color: #fff;
  max-width: 100vw;

  .app-nav-bar {
      background-color: #DFDFDF;
      height: 40px;
      outline: none;
      display: flex;
      justify-content: space-between;

      .left-menus {
        float: left;
      }

      .right-menus {
        display: flex;
        float: right;
        align-items: center;
        margin-left: 10px;
        justify-content: flex-end;
      }

      .dropdown {
        background-color: #c0bcc4;
        color: #fff;
        width: 300px;
        height: 40px;
        border-radius: 0;
        text-align: center;
        border: none;
        cursor: pointer;
        border-right: 3px solid white;

        &:focus {
          outline: none;
        }
      }

      .option {
        background-color: #DFDFDF;
        border-radius: 0;
        border-right: 2px solid #fff;
      }

      .input-label {
        background-color: #c0bcc4;
        color: #fff;
        padding: 0 10px;
        height: 40px;
        align-items: center;
        display: flex;
        justify-content: center;
        cursor: pointer;
        border-right: 3px solid white;
      }
  }

  .header-section {
    margin: 0 127px;

    .app-logo {
      margin-top: 40px;
    }

    .app-header-img {
        position: relative;
        color: #fff;

        .text-on-image {
          position: absolute;
          left: 2%;
          bottom: 10%;

          .big-font {
            font-size: 50px;
            margin-bottom: 10px;
          }
         }
     
        .img {
            width: -webkit-fill-available;
            /* height: 485px; */
            opacity: 0.8;
            filter: brightness(50%);
        }

        .text {
            position: absolute;
            bottom: 0px;
        }
    }

    .app-breadcrumbs {
      display: flex;
      padding: 20px 0;

      .icon {
        width: 25px;
        height: 25px;
        margin-right: 15px;
      }

      .label {
        font-weight: bold;
        cursor: pointer;
      }

      .breadcrumb-list-items {
        display: flex;
        list-style: none;
        font-weight: bold;
        margin: 5px;
        padding: 0;
        cursor: pointer;
      }
    }
  }

  .app-list-items {
    .list-items {
      display: flex;
      list-style: none;
      justify-content: space-between;
      color: #000;
      font-weight: bold;
      cursor: pointer;
      padding: 15px 0;
    }
  }
}


/* App footer */
.footer {
  bottom:0;
  margin-top: auto;
  max-width: 100vw;
  /* position: absolute; */

  .items {
      justify-content: space-around;
      margin: 127px 0px ;
      display: flex;
      /* padding: 0 50px; */

      /* .image {
          width: 30%;
          height: 284px;
      } */
  }

  .footer-image {
      width: 100%;
      height: 306px;
  }

  .options {
      text-align: end;
      margin-right: 127px;
  
      .icon-image {
        width: 36px;
        height: 36px;
        margin-left: 20px;
        cursor: pointer;
      }
    }
}


/* this css is for radiogroup */

label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 15px;
}

input[type='radio'] {
  margin-right: 8px;
}


#preview {
  height: 100vh;
  justify-content: center;
  width: 100%;
}