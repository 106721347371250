.layout {
  display: flex;
  width: 100%
}
.MuiFormHelperText-root{
    color: #e74c3c !important;
}


.aligned_form {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

label {
  width: 50%; /* Customize as needed */
}


.sectionUL {
  list-style-type: none;
}
.sectionLI {
  display: flex;
  width: 100%;
  padding: 5px;
  margin-bottom: 25px;
  text-align: left;
  align-items: center;

  .active-step {
    font-weight: 600;
    font-size: 24px;
    line-height: 39px;
    color: #00000099;
    cursor: pointer;
    width: 70%;
    padding: 10px;
  }

  .inactive-step {
    font-weight: 600;
    font-size: 24px;
    line-height: 39px;
    color: #C0BCC4;
    cursor: pointer;
    width: 70%;
    padding: 10px;
  }
}
.sectionAnchor {
  font-size: large;
  cursor: pointer;
  font-weight: 500;
}

.form-container {
  margin-top: 10px;
  padding: 20px;
  width: 100%;
  background-color: #fafafa;
  border: #e9e3e3 solid 1px;
  border-radius: 8px;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  gap: 1rem; /* Spacing between form fields */
}

.form-field {
   width: 100%;  /*Fill the available width */
  padding: 12px 20px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.submit-button {
  width: 100%;
  background-color: #04aa6d;
  color: white;
  padding: 14px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #45a049;
}

.aSelected {
  color: #d22630;
  font-size: large;
  cursor: pointer;
  font-weight: 500;
}
