.box1 {
    margin: auto;
    position: relative;
    margin-bottom: 20px;
}

.img1 {
    width: 100%;
    filter: brightness(60%);
}

.th1 {
    position: absolute;
    top: 60%;
    left: 7%;
    color: white;
    font-size: 40px !important;
}

.marginBottom20 {
    margin-bottom: 20px;
}

.box3 {
    width: 90%;
    margin: 10px 10px 5px 10px;
    padding-left: 40px;
    display: grid;
}

.box4 {
    margin: 10px 10px 5px 10px;
    padding-left: 40px;
}
.select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 10px;
}

.page-loader{
    background: rgba(0,0,0,0.2);
    top: 0;
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 999;
}

.page-loader > .loader {
    position: absolute;
    top: 50%; 
    right: 50%;
    transform: translate(50%,-50%);
}